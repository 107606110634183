/* eslint-disable react/prop-types */
import {
  Button, CardContent, Grid, Hidden, Typography,
} from '@mui/material';
import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import YouTubeIcon from '@mui/icons-material/YouTube';
import ImageGallery from 'react-image-gallery';
import {
  Testimonials, Wrapper, WhoWeAre, GroupedProjectsCarousel,
} from '../components';
import getProjects from '../firebase/getProjects';

export default function IndexPage({ data }) {
  const {
    heroImages,
    testimonials,
  } = data;

  const {
    projects,
  } = getProjects();

  const [images, setImages] = useState();
  useEffect(() => {
    setImages(heroImages.nodes);
  }, [heroImages]);

  return (
    <Wrapper>
      <Grid
        container
        direction="row"
        justifyContent="center"
        align="center"
      >
        <Grid item xs={12}>
          {images && (
          <ImageGallery
            items={images}
            showThumbnails={false}
            showBullets
            showPlayButton={false}
            showFullscreenButton={false}
          />
          )}
        </Grid>
        <Grid item xs={12}>
          <WhoWeAre enableReadMore />
        </Grid>
        <Hidden only="xs">
          <Grid item xs={12}>
            <GroupedProjectsCarousel projects={projects} groupSize={3} />
          </Grid>
        </Hidden>
        <Hidden smUp>
          <Grid item xs={12}>
            <GroupedProjectsCarousel projects={projects} groupSize={1} />
          </Grid>
        </Hidden>
        <Grid item xs={12}>
          <div style={{
            width: '100vw',
            paddingTop: '3em',
            paddingBottom: '3em',
            backgroundColor: 'black',
          }}
          >
            <CardContent>
              <Button
                size="large"
                startIcon={<YouTubeIcon fontSize="large" color="error" />}
                href="https://www.youtube.com/c/PoshPadsRealty"
                target="_blank"
              >
                <Typography variant="h6" color="secondary">
                  Youtube
                </Typography>
              </Button>
            </CardContent>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Testimonials testimonials={testimonials.nodes} />
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export const query = graphql`
  {
    heroImages:allFile(filter: {relativePath: {regex: "/poshpads-hero-image/"}}) {
      nodes {
        original:publicURL
        thumbnail:publicURL
      }
    }
    testimonials: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/testimonials/"}}) {
      nodes {
        id
        frontmatter {
          company
          rating
          text
          name
          featuredImage{
            publicURL
            childImageSharp {
              gatsbyImageData(
                width: 4000
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  }
`;
